<template>
  <div class="home">
    <div class="home-in">
      <h2>What would you do?</h2>
      
      <ul>
        <li>
          <router-link to="/search/imei?type=coupon">
            <cube-button :primary="true" :outline="true">我的优惠券</cube-button>
          </router-link>
        </li>
        <li>
          <router-link to="/search/imei?type=apply">
            <cube-button :primary="true" :outline="true">服务申请</cube-button>
          </router-link>
        </li>
        <li>
          <router-link to="/imei/search">
            <cube-button :primary="true" :outline="true">修改IMEI</cube-button>
          </router-link>
        </li>
        <li>
          <router-link to="/user/imei">
            <cube-button :primary="true" :outline="true">修改身份证</cube-button>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {

    }
  },
  mounted () {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
ul{
  width: 70%;
  margin: 0 auto;
}
li{
  padding: 15px 30px;
}
.home{
  background-color: #208170;
  color: #fc9153;
  min-height: 100vh;
  text-align: center;
  display: flex;
  justify-items: center;
  align-items: center;  
}
.home-in{
  width: 100%;
  h2{
    font-size: 56px;
    padding: 20px 0 80px 0;
  }
}
.logo{
  display: block;
  width: 40%;
  margin: 0 auto;
}
</style>